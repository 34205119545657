import axios from "axios";


export class ApiClient {

     async getQueryStats() {
         const url = "https://tedpj6tc5c.execute-api.eu-north-1.amazonaws.com/staging/stats/query"
         const options = {
         }

            return axios.get(url, options)
                .then((response)  => {
                return response.data;
            }
        )
        .catch(e => {
                console.log(e);
            })
        }

}


