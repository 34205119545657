import './App.css';
import React, {useEffect, useState} from "react";
import {ApiClient} from "./api/handler";
import CircularProgress from '@mui/material/CircularProgress';


const App = () => {

    const [numQueries, setNumQueries] = useState();
    const [topQueries, setTopQueries] = useState();
    const [numQueriesLastDay, setNumQueriesLastDay] = useState();
    const [numUniqueVisitorsLastDay, setNumUniqueVisitorsLastDay] = useState();
    const [isLoading, setIsLoading] = useState(true)

    const getStats = async () => {
        const apiInstance = new ApiClient()
        const response = await apiInstance.getQueryStats()
        setNumQueries(response["numQueries"][0]["Count(*)"])
        setTopQueries(response["topQueries"])
        setNumQueriesLastDay(response["numQueriesLastDay"][0]["amount"])
        setNumUniqueVisitorsLastDay(response["numUniqueVisitorsLastDay"][0]["unique_users_today"])
        setIsLoading(false)
    }

    useEffect(() => {
            try {
                getStats();
            } catch (e) {
                console.log(e)
            }
        }
        , [])

    return <div className="App">
        <div className="body">
            {!isLoading &&
            <div className="container">
                <div className="left-div">

                    <div className="stat-container min-width">
                        <h1 className="total-num-searches"> Antal sökningar </h1>
                        <br/> <br/>
                        <h1 className="val-total-num-searches"> {numQueries} </h1>
                    </div>

                </div>

                <div className="left-div">

                    <div className="stat-container min-width">
                        <h1 className="total-num-searches small"> Antal sökningar senaste dygnet </h1>
                        <br/> <br/>
                        <h1 className="val-total-num-searches"> {numQueriesLastDay} </h1>
                    </div>

                    <div className="stat-container last-day-container min-width">
                        <h1 className="total-num-searches small"> Unika användare senaste dygnet </h1>
                        <br/> <br/>
                        <h1 className="val-total-num-searches"> {numUniqueVisitorsLastDay} </h1>
                    </div>

                </div>


                <div className="stat-container">

                    <h1> Toppsökningar </h1>

                    <div className="query-container">
                        <div> {topQueries.map(elem => {
                            return <h2 className="num-query">{elem["query"]}</h2>
                        })}
                        </div>

                        <div className="numbers">
                            {topQueries.map(elem => {
                                return <h2 className="num-query">{elem["amount"]}</h2>
                            })}
                        </div>
                    </div>

                </div>

            </div>
            }
            {isLoading &&
            <CircularProgress className="progress-circle"/>
            }
        </div>
    </div>;

}


export default App;
